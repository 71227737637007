/* eslint-disable max-len */
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { NavClose, NavOpen } from "../../assets/images/icons/NavIcons";
import Button from "../Button";
import Logo from "../../assets/images/cn-logo-dark.png";
import H4 from "../Typography/H4";
import H5 from "../Typography/H5";
import { getUserState } from "../../utils/utils";
import { connect } from "react-redux";
import { fetchListingCategories } from "../../redux/listings/listings.actions";
import { createStructuredSelector } from "reselect";
import { selectListingCategories } from "../../redux/listings/listings.selectors";
import { getSubDomainName } from "../../Services/helpers";
import { SearchNormalIcon } from "../../assets/images/icons/search-normal";

// import Link from "next/link";
// import Container from "../Container";



const Header = ({ fetchListingCategories, listing_categories, withSearch }) => {
  const selector = useSelector(state => state.authReducer);
  const userState = getUserState(selector);
  const [navOpen, setNavOpen] = useState(false);
  const imgRef = useRef(null);
  const myRefs = useRef([]);

  const handleOpen = () => {
    setNavOpen(!navOpen);
  };

  const [searchValue, setSearchValue] = useState();

  const links = [
    {
      title: "Articles",
      href: "https://articles.connectnigeria.com",
    },
    {
      title: "CNTV",
      href: "https://cntv.connectnigeria.com",
    },
    {
      title: "Advertise",
      href: `${process.env.REACT_APP_CN_BASE_URL}/advertise`,
    },
    {
      title: "Business Mixer",
      href: `${process.env.REACT_APP_CN_BASE_URL}/bizmix`,
    },
    {
      title: "ClubConnect",
      href: `https://club.connectnigeria.com/`,
    },
  ];

  useEffect(() => {
    fetchListingCategories();
  }, [fetchListingCategories]);

  return (
    <header className="top-0 right-0 left-0 z-50 absolute">
      <nav className="bg-white/90 w-full top-0 fixed backdrop-blur-3xl">
        <div className=" mx-auto px-4 lg:px-8 ">
          <div className="flex flex-row py-4 items-center  justify-between lg:py-3">
            <div className=" flex items-center">
              <a
                className="w-[87px] lg:w-40 mr-4 lg:mr-0"
                href={process.env.REACT_APP_CN_BASE_URL}
              >
                <img
                  className=" "
                  src={Logo}
                  alt="connectNigeria Logo"
                />
              </a>
              <div className="hidden lg:block">
                <div className="flex space-x-4 ml-12 items-baseline">
                  <div className="font-medium text-sm text-mainBlack toggleable lg:text-base">
                    <input
                      type="checkbox"
                      value="selected"
                      id="toggle-one"
                      className="toggle-input"
                    />
                    <label
                      htmlFor="toggle-one"
                      className="cursor-pointer py-2 px-3 block "
                    >
                      Browse ConnectNigeria
                    </label>
                    <div
                      role="toggle"
                      className="bg-white mt-3.5 mb-16 mega-menu   sm:mb-0 "
                    >
                      <div className="flex  flex-wrap h-full w-full justify-between ">
                        <div className="bg-mainGrey-whitesmoke flex flex-col  w-full py-10	 px-10 transition ease-out duration-300 group justify-between sm:w-1/2  lg:w-1/5 hover:bg-mainLightGreen-lighter    ">
                          <H4 weightClass="font-bold" className="leading-tight	">
                            Browse <br /> Connect Nigeria&#39;s Business
                            categories
                          </H4>
                          {/* <span>
                            <a href="#" className="text-base transition ease-out  duration-150 block group-hover:text-mainLightGreen-100">View all categories</a>

                          </span> */}
                        </div>
                        <div className="bg-white shadow-sm w-full lg:w-4/5   ">
                          <div className="grid menu-mega md:grid-cols-2 lg:grid-cols-4">
                            <div
                              onMouseOver={() => {
                                imgRef.current.src =
                                  require("../../assets/images/icons/arrow-right-long.svg").default;
                              }}
                              onMouseOut={() => {
                                imgRef.current.src =
                                  require("../../assets/images/icons/vuesax-linear-arrow-right.svg").default;
                              }}
                              className="border border-r border-b border-t-0 border-l-0 px-10  pt-10 pb-10 transition ease-out duration-300 group hover:bg-mainLightGreen-lighter"
                            >
                              <a
                                href={getSubDomainName("business")}
                                className="flex flex-col h-full"
                              >
                                <H5 weightClass="font-bold" className="mb-4">
                                  Businesses{" "}
                                </H5>
                                <p className="mb-4 pr-5 text-17px text-mainBlack-80">
                                  Nigeria Business Directory Increase your
                                  business visibility and get discovered
                                </p>

                                <div className="flex mt-auto text-base transition ease-out duration-150 group-hover:text-mainLightGreen-100">
                                  Explore
                                  <img
                                    className="ml-4 "
                                    alt=""
                                    ref={imgRef}
                                    src={
                                      require("../../assets/images/icons/vuesax-linear-arrow-right.svg")
                                        .default
                                    }
                                  />
                                </div>
                              </a>
                            </div>
                            {listing_categories?.map((category, index) => (
                              <div
                                onMouseOver={() => {
                                  myRefs.current[index].src =
                                    require("../../assets/images/icons/arrow-right-long.svg").default;
                                }}
                                onMouseOut={() => {
                                  myRefs.current[index].src =
                                    require("../../assets/images/icons/vuesax-linear-arrow-right.svg").default;
                                }}
                                key={category?.id}
                                className="border border-r border-b border-t-0 border-l-0 px-10  pt-10 pb-10 transition ease-out duration-300 group hover:bg-mainLightGreen-lighter"
                              >
                                <a
                                  href={getSubDomainName(category.name)}
                                  className="flex flex-col"
                                >
                                  <H5 weightClass="font-bold" className="mb-4">
                                    {category?.name}{" "}
                                  </H5>
                                  <p className="mb-4 pr-5 text-17px text-mainBlack-80">
                                    {category?.description}
                                  </p>

                                  <div className="flex mt-auto text-base transition  ease-out duration-150 group-hover:text-mainLightGreen-100">
                                    Explore
                                    <img
                                      className="ml-4"
                                      ref={(el) => (myRefs.current[index] = el)}
                                      id={`${category?.name}`}
                                      alt="arrow"
                                      src={
                                        require("../../assets/images/icons/vuesax-linear-arrow-right.svg")
                                          .default
                                      }
                                    />
                                  </div>
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <a
                    className="font-medium text-mainBlack text-sm py-2 px-3 lg:text-base"
                    rel="noreferrer"
                    target={"_blank"}
                    href={"https://articles.connectnigeria.com/"}
                  >
                    Articles
                  </a>
                  <a
                    className="font-medium text-mainBlack text-sm py-2 px-3 lg:text-base"
                    rel="noreferrer"
                    target={"_blank"}
                    href={"https://cntv.connectnigeria.com/"}
                  >
                    CNTV
                  </a>
                  <a
                    className="font-medium text-mainBlack text-sm py-2 px-3 lg:text-base"
                    href={`${process.env.REACT_APP_CN_BASE_URL}/advertise`}
                  >
                    Advertise
                  </a>
                  <a className="text-mainBlack text-sm py-2 px-3 lg:text-base font-medium" href={'https://connectnigeria.com/bizmix'}>
                    Business Mixer
                  </a>
                  <a className="text-mainBlack text-sm py-2 px-3 lg:text-base font-medium" href={'https://club.connectnigeria.com/'}>
                    ClubConnect
                  </a>
                </div>
              </div>
            </div>

            {withSearch && <form onSubmit={(e) => {
              e.preventDefault();

              window.location.replace('/search?q=' + searchValue);
            }} className="lex-initial md:w-full xl:w-3/12 ml-auto" >
              <div>
                <div className="mt-1 flex  p-1 bg-white mx-auto border border-[#BBBABB] rounded-[15px]">
                  <input
                    type="text"
                    name="search"
                    id="search"
                    value={searchValue || ''}
                    onChange={(e) => setSearchValue(e.target.value)}
                    autoComplete="search"
                    placeholder={"Search ConnnectNigeria"}
                    className="bg-transparent placeholder:text-sm
                     placeholder:text-mainBlack-60   border-0 border-[#E3E3E4]  
                       sm:text-sm w-full
                       focus:ring-0 grow
                       focus:border-gray-300
                      "
                  />
                  <button type='submit' className="p-2">
                    <SearchNormalIcon />
                  </button>

                </div>
              </div>
              <button hidden type='submit'></button>
            </form>}


            <div className="hidden lg:block">
              <div className="flex ml-4 items-center md:ml-6">
                <Button
                target={'_blank'}
                 heightClass="h-11"
                 buttonRadius="largeRadius"
                 variant="green"
                  className="py-6 px-14 mr-4  whitespace-nowrap text-base lg:text-base"
                  href={`${process.env.REACT_APP_CN_BASE_URL}/create-listing`}
                >
                  List Now
                </Button>

                <div className="text-left relative inline-block">
                  <div>
                    {userState.isLoggedIn ? (
                      <Button
                        heightClass="h-11"
                        buttonRadius="largeRadius"
                        className="text-base"
                        href={`${process.env.REACT_APP_CN_BASE_URL}/account/start`}
                      >
                        My Account
                      </Button>
                    ) : (
                      <Button
                        heightClass="h-11"
                        buttonRadius="largeRadius"
                        className="text-base"
                        href={`${process.env.REACT_APP_CN_BASE_URL}/login`}
                      >
                        Login
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex -mr-2 ml-auto lg:hidden">
              <button
                onClick={handleOpen}
                className="rounded-md  p-2 text-gray-800 inline-flex items-center justify-center hover:text-gray-300 focus:outline-none"
              >
                {!navOpen ? <NavOpen /> : <NavClose />}
              </button>
            </div>
          </div>
        </div>

        <div
          className={`${!navOpen ? "hidden scale-out-hor-right" : "block scale-in-hor-right"
            } vista-white h-screen lg:hidden overflow-y-auto pb-24`}
        >
          <div className="container flex flex-col mx-auto px-2 gap-2 sm:px-3">
            <div className="font-medium text-mainBlack p-2" href="/#">
              Browse ConnectNigeria
              <div className="grid gap-4 grid-cols-2">
              <a
                  href={getSubDomainName('business')}
                  className="rounded font-bold text-lg p-1 transition ease-out duration-150 block hover:text-mainLightGreen-100"
                >
                   Businesses
                </a>
                {listing_categories?.map((category) => (
                  <a
                    key={category?.id}
                    href={getSubDomainName(category.name)}
                    className="rounded font-bold text-lg p-1 transition ease-out duration-150 block hover:text-mainLightGreen-100"
                  >
                    {category?.name}
                  </a>
                ))}
              </div>
            </div>

            {links.map((link, i) => (
              <a
                className="rounded-xl text-base font-medium text-mainBlack py-1 px-2 hover:bg-white"
                href={link.href}
                key={i}
              >
                {link.title}
              </a>
            ))}

                <Button
                target={'_blank'}
                 heightClass="h-11"
                 buttonRadius="largeRadius"
                 variant="green"
                  className="py-6 px-14  justify-center whitespace-nowrap text-base lg:text-base"
                  href={`${process.env.REACT_APP_CN_BASE_URL}/create-listing`}
                >
                  List Now
                </Button>

            <div className="py-2">
              {userState.isLoggedIn ? (
                <Button
                  heightClass="h-11"
                  buttonRadius="rounded-xl"
                  className="w-full justify-center"
                  target="_blank"
                  href={`${process.env.REACT_APP_CN_BASE_URL}/account/start`}
                  // variant="green"
                >
                  My Account
                </Button>
              ) : (
                <Button
                  heightClass="h-11"
                  buttonRadius="rounded-xl"
                  className="w-full justify-center"
                  target="_blank"
                  href={`${process.env.REACT_APP_CN_BASE_URL}/login`}
                  // variant="green"
                >
                  Login
                </Button>
              )}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchListingCategories: () => dispatch(fetchListingCategories()),
  };
};

const mapStateToProps = createStructuredSelector({
  listing_categories: selectListingCategories,
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
