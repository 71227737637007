import { Fragment, lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
// , toast
import { ToastContainer } from 'react-toastify';
import LoadingScreen from '../Components/LoadingScreen/LoadingScreen';
import MainArea from '../Layouts/Users/MainArea';

export const renderRoutes = (routes = []) => {
  return (
    <>
      <Suspense fallback={<LoadingScreen />}>
        <Switch>
          {routes.map((route, i) => {
            const Guard = route.guard || Fragment;
            const Component = route.component;
            const Layout = route.layout || Fragment;

            return (
              <Route
                key={i}
                path={route.path}
                exact={route.exact}
                render={props => {
                  return (
                    <Guard>
                      <Layout>
                        {route.routes ? (
                          renderRoutes(route.routes)
                        ) : (
                          <Component {...props} />
                        )}
                      </Layout>
                    </Guard>
                  );
                }}
              />
            );
          })}
        </Switch>
      </Suspense>
      <ToastContainer />
    </>
  );
};

export const routes = [
  {
    exact: true,
    path:  `/search`,
    layout: MainArea,
    component: lazy(() => import('../Views/Users/Events/eventSearchResults'))
  },
  {
    exact: true,
    path: `/all`,
    layout: MainArea,
    component: lazy(() => import('../Views/Users/Events/allEvents'))
  },

  {
    exact: true,
    path:`/`,
    layout: MainArea,
    component: lazy(() => import('../Views/Users/Events/allEvents'))
  },
  {
  exact: true,
  path: `/seller/:sellerId`,
  layout: MainArea,
  component: lazy(() => import("../Views/Users/Seller")),
},
  {
    exact: true,
    path: `/category/:category`,
    layout: MainArea,
    component: lazy(() => import("../Views/Users/Events/allEvents")),
  },
  {
    exact: true,
    path: `/category`,
    layout: MainArea,
    component: lazy(() => import("../Views/Users/Events/allEvents")),
  },
  {
    exact: true,
    path: '/:listingId',
    layout: MainArea,
    component: lazy(() => import('../Views/Users/Events/EventDetails'))
  },
  {
    path: '*',
    routes: [
      {
        component: lazy(() => import('../Components/Errors/Notfound'))
      }
    ]
  }
];
